import React from "react";
import { graphql } from "gatsby";
import { Grid } from "@mui/material";
import { genHelmet } from "../actions/baseActions";
import PageLayout from "../components/pageLayout";
import { Button } from "gatsby-theme-material-ui";

const CapabilityStatement = (props) => {
  console.log(props.data);
  const {
    capStatement: {
      mediaElement: { url: capStatementUrl },
      coverImage,
    },
    profile: {
      mediaElement: { url: profileUrl },
    },
  } = props.data;

  const styles = {
    root: {
      height: "60vh",
    },
  };

  return (
    <>
      {genHelmet(
        {
          ...props.data.capStatement,
          title: "Capability Statement",
        },
        {
          type: "page",
          // siteData: pageData,
          path: props.path,
        }
      )}
      {/* --- LAYOUT --- */}
      <PageLayout location={props.location}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={styles.root}
          spacing={8}
        >
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              href={capStatementUrl}
              color="secondary"
              sx={styles.button}
            >
              Download Capability Statement
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              href={profileUrl}
              color="secondary"
              sx={styles.button}
            >
              Download Company Profile
            </Button>
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
};

export default CapabilityStatement;

export const pageQuery = graphql`
  {
    capStatement: contentfulMediaAsset(slug: { eq: "capability-statement" }) {
      headline
      slug
      title
      mediaElement {
        description
        filename
        mimeType
        url
      }
      coverImage {
        title
        description
        gatsbyImageData
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
    }
    profile: contentfulMediaAsset(slug: { eq: "company-profile" }) {
      headline
      slug
      title
      mediaElement {
        description
        filename
        mimeType
        url
      }
    }
  }
`;
